.builder-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  .nodes-container {
    width: 250px;
    border: 1px solid #afb5d0;
    border-radius: 5px;
    padding: 5px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    .nodes-inner-container {
      // border: 1px solid darkslategray;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      position: relative;
      .final-inner {
        position: absolute;
        padding-top: 4px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // border: 2px solid blue;
        overflow-y: scroll;
        .card {
          // height: 250px;
          flex: 0 0 auto;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          margin-left: 3px;
          margin-right: 3px;
          margin-bottom: 10px;
          cursor: pointer;
          &:hover {
            box-shadow: rgba(61, 5, 227, 0.2) 0px 4px 10px 0px;
          }
        }
        .card .card-body {
          padding: 10px;
        }
      }
    }
  }

  .react-flow-container {
    flex: 1 0 auto;
    .react-flow {
      border: 1px solid #afb5d0;
      border-radius: 5px;
      background-color: #ffffff;
    }
  }
}
