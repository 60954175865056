.easy-edit-button-wrapper {
  display: block;
  padding-top: 10px;
}

.bordered-container {
  border: 1px solid #afb5d0;
  padding: 10px;
  border-radius: 10px;
}

.divider {
  height: 2px;
  display: block;
  background-color: #e4e6ef;
  width: 100%;
}

.form-box {
  border: 1px solid #afb5d0;
  border-radius: 10px;
  padding: 10px;
}

.picker {
  position: relative;
}

.swatch {
  width: 50px;
  height: 100%;
  border-radius: 0 8px 8px 0;
  border: 5px solid #fff;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), inset 0 0 0 1px rgb(0 0 0 / 10%);
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
.popover {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.live-ui-container {
  height: 100%;
  iframe {
    width: 100%;
    height: 650px;
    border-radius: 7px;
  }
}

.uploader {
  display: flex;
  height: 55px;
  .logo-preview {
    display: flex;
    flex: 1;
  }
  .image-upload-button {
    display: flex;
    padding: 7px;
  }
}

.subscriberName {
  color: black;
  font-size: 1.7rem;
  font-weight: 600;
}

code[class*='language-'],
pre[class*='language-'] {
  font-size: 13px !important;
  line-height: 18px !important;
}
