.job-container {
  display: flex;
  border: 1px solid #afb5d0;
  padding: 10px;
  border-radius: 10px;
  flex-direction: column;
  font-size: 1.05rem;
  .status {
    background-color: orange;
    color: white;
    padding: 2px 6px;
    border-radius: 5px;
    font-weight: bold;
    &.created {
      background-color: orange;
    }
    &.crawling {
      background-color: orange;
    }
    &.crawl-success {
      background-color: orange;
    }
    &.training {
      background-color: purple;
    }
    &.train-success {
      background-color: green;
    }
    &.failed {
      background-color: red;
    }
  }
  .urls-container {
    max-height: 400px;
    overflow-y: scroll;
    border: 1px solid #afb5d0;
    border-radius: 10px;
    width: 100%;
    .url {
      width: 100%;
      overflow: hidden;
      border-bottom: 1px solid #afb5d0;
      height: 50px;
      display: flex;
      align-items: center;
      vertical-align: middle;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 10px;
      &:last-child {
        border: none;
      }
    }
  }
}
